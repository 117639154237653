import { set } from "date-fns";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import CountDown from "./CountDown";
import LaunchQuest from "./LaunchQuest";
import QuestRoom from "./QuestRoom";

function QuestClient() {

    const BASE_URL = process.env.REACT_APP_API_BASE_URL
    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL

    const [token, setToken] = useState(null);
    const [tokenValid, setTokenValid] = useState(false);

    const [error, setError] = useState(null);

    const [quest, setQuest] = useState(null);
    const [booking, setBooking] = useState(null);
    const [prompts, setPrompts] = useState(null);

    const [timerCompleted, setTimerCompleted] = useState(false);

    const [questStarted, setQuestStarted] = useState(false);

    const backgroundCSS = {
        backgroundImage: `url(${MEDIA}/media/patterns/large-low-opacity.svg)`,
        backgroundRepeat: "repeat, repeat",
        backgroundPosition: "cover",
    };

    const joinToken = useParams().joinToken;

    useEffect(() => {
        setToken(joinToken)
        if (joinToken) {
            validateToken(joinToken);
        }
    }, [joinToken]);

    useEffect(() => {
       if (quest) {
        const fetchPrompts = async () => {
            const response = await fetch(`${BASE_URL}/prompts/${quest.id}`);
            if (!response.ok) {
                const errorText = await response.text();
                console.error("Error:", response.status, errorText);
                return;
            }
            const data = await response.json();
            setPrompts(data);
            console.log(data);
        }
        fetchPrompts();
       }
    }, [quest]);



    const validateToken = async (questToken) => {
        const trimmedToken = questToken.trim();
        const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

        if (!uuidRegex.test(trimmedToken)) {
            setError("Invalid join token");
            return;
        }
        try {
            setError(null);
            const response = await fetch(`${BASE_URL}/join-quest/${trimmedToken}`);
            const data = await response.json();

            if (!response.ok) {
                setError(data.error);
                return;
            }
            console.log(data);

            if (data.valid) {
                setTokenValid(true);
                setQuest(data.quest);
                setBooking(data.booking);
            } else {
                console.log("Token is not valid");
            }

        } catch (err) {
            console.log("Fetch error:", err);
        }
    };

    const handleTimerComplete = () => {
        setTimerCompleted(true); // Update state to trigger re-render
    };



    if (!token || !tokenValid) {
        return (
            <div style={backgroundCSS} className="QuestClient QuestClientCenterContent">
                <label style={{fontSize: "20px"}}>Please enter your join token</label>
                <input style={{fontSize: "20px"}} type="text" onChange={(e) => setToken(e.target.value)}/>
                {error ? <p style={{fontSize: "20px", fontWeight: "bold", color: "red"}}>{error}</p> : null}
                <button onClick={() => validateToken(token)} style={{fontSize: "20px"}}>Join Quest</button>
            </div>
        )
    }

    if (tokenValid && booking && quest && prompts) {
        const bookingDateTime = new Date(`${booking.date}T${booking.time}`);
        const image = quest.banner_images[2]?.image ? `${MEDIA}${quest.banner_images[2].image}` : null
        const QuestBannerBackgroundCSS = {
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover"
        }
        if (bookingDateTime > Date.now()) {
            return (
                <div style={QuestBannerBackgroundCSS} className="QuestClient QuestClientCenterContent">
                    <CountDown 
                        booking={booking}
                        quest={quest}
                        bookingDateTime={bookingDateTime}
                        onComplete={handleTimerComplete}
                    />
                </div>
            )
        } else if (!questStarted) {
            return (
                <div style={QuestBannerBackgroundCSS} className="QuestClient QuestClientCenterContent">
                    <LaunchQuest token={token} quest={quest} booking={booking} setQuestStarted={setQuestStarted}/>
                </div>
            )
        } else {
            return (
                <QuestRoom
                    quest={quest}
                    booking={booking}
                    prompts={prompts}
                    token={token}
                />
            )
        }
    }

    // return (
    //     <div style={backgroundCSS} className="QuestClient">
            
    //     </div>
    // )
}

export default QuestClient