import React, { useEffect } from "react";
import {holdDropdown, ardiIcon} from "../../tools";

function Navbar() {
    
    useEffect(() => {
        holdDropdown('NavGames', 'NavGamesDrop');
    }, []);


    return (
        <div className="Nav">
            <a href="/">
                <div className="NavIcon">
                {ardiIcon("#F15727")}
                <span>ARDI</span>
                </div>
            </a>

            <div className="NavLinks">
                <div className="NavGames">
                    <a href="/#WhatKindOfQuester">Our Quests</a>
                    <div className="NavGamesDrop">
                        <a href="/quests/cityquest">CityQuest</a>
                        <a href="/quests/homequest">HomeQuest</a>
                        <a href="/quests/officequest">OfficeQuest</a>
                        {/* <a href="/games/touristquest">TouristQuest</a> */}
                        {/* <a href="/games/escapequest">EscapeQuest</a> */}
                        {/* <a href="/games/paperquest">PaperQuest</a> */}
                        {/* <a href="/games/puzzlequest">PuzzleQuest</a> */}
                        {/* <a href="/games/schoolquest">SchoolQuest</a> */}
                    </div>
                </div>
                <a href="/creators">Creators</a>
                <a href="/#WhyChooseUs">About Us</a>
                <a href="/news">News</a>
                <a href="/faq">FAQ</a>
                {/* <a href="/">Media</a> */}
                {/* <a href="/">Downloads</a> */}
            </div>

            <div className="NavButton">
                {/* <button>Login</button> */}
                {/* <button>Sign-Up</button> */}
            </div>

            {/* <div className="nav_lang_drop">
                <a href="/">English</a>
                <a href="/">Swedish</a>
            </div> */}
        </div>
    );
}

export default Navbar