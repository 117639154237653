import React from "react";

function CategoryBanner({category, scrollToWhatCustomersSay}) {
    // console.log(category);

    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL

    const buttonCSS = {
        backgroundColor: category.primary_color,
        color: "#FFF",
        border: "none",
    }
    const buttonHoverCSS = {
        backgroundColor: "#FFF",
        color: category.primary_color,
        border: `1px solid ${category.secondary_color}`,
    }

    const bannerCSS = {
        backgroundColor: category.primary_color,
    }

    return (
        <div className="banner">
            <div className="bannerImage"></div>
            <div style={bannerCSS} className="bannerColorBlock"></div>
            
            <div className="CategoryBannerContent">
                <div className="CategoryBannerContentLeft">
                    <h1>{category.title}</h1>
                    <p>{category.subtitle}</p>

                    <div className="CategoryBannerButtons">

                        <a href={`/booking/c${category.id}`}>
                            <button style={buttonCSS} onMouseOver={(e) => {
                                    Object.assign(e.target.style, buttonHoverCSS);
                                }} onMouseOut={(e) => {
                                    Object.assign(e.target.style, buttonCSS);
                                }}>
                                    Book Now
                            </button>
                        </a>

                        <button style={buttonCSS} onMouseOver={(e) => {
                                Object.assign(e.target.style, buttonHoverCSS);
                            }} onMouseOut={(e) => {
                                Object.assign(e.target.style, buttonCSS);
                            }}
                            onClick={scrollToWhatCustomersSay}
                            >
                                Reviews
                        </button>

                    </div>
                </div>
                <div className="CategoryBannerContentRight">
                    {category.video ? 
                        <div className="VideoContainer">
                            <iframe 
                                src={category.video}
                                title="YouTube video player" 
                                referrerPolicy="strict-origin-when-cross-origin" 
                                allowFullScreen
                                >
                            </iframe>
                        </div>
                    :
                        <img src={`${MEDIA}${category.card_image}`} alt="Place Holder"/>
                    }
                </div>


                 {/* <div className="bannerContentRight">
                    <img src="https://www.figma.com/file/njZZtCimk2hWd9uH9mUT7v/image/d491e88904c6655307d3dd0158a305777df38c42" alt="Place Holder"/>
                </div>  */}
            </div>
        </div>
    );
}

export default CategoryBanner