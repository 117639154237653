import React from "react";

function QuestionTab({sessionData, setNewQuestion}) {

    setNewQuestion(false);

    const renderQuestions = () => {
        if (sessionData?.events) {
            const questionItems = sessionData.events.filter(event => event.event_type === "question found");

            if (questionItems.length === 0) {
                return <p>Nothing to see here yet, keep investigating.</p>;
            }

            return questionItems.map((event) => (
                <div className="QuestionTabItem" key={event.id}>
                    <br/>
                    <h3>{event.event_data.question}?</h3>
                    <div className="QuestionTabItemOptions">
                        <p>{event.event_data.option_1}</p>
                        <p>{event.event_data.option_2}</p>
                        <p>{event.event_data.option_3}</p>
                        <p>{event.event_data.option_4}</p>
                    </div>
                </div>
            ))
        }
    }


    return(
        <div className="QuestionTab">
            <h1>Questions</h1>
            <div className="QuestionTabContent">
                {renderQuestions()}
            </div>
        </div>
    )
}

export default QuestionTab