import React, { useState, useEffect } from "react";

import WaitForCaptain from "./WaitForCaptain";
import QuestTimer from "./QuestTimer";

import EvidenceTab from "./tabs/EvidenceTab";
import HintsTab from "./tabs/HintsTab";
import HistoryTab from "./tabs/HistoryTab";
import MapTab from "./tabs/MapTab";
import QuestionsTab from "./tabs/QuestionsTab";
import QuestTab from "./tabs/QuestTab";

import FooterNav from "./FooterNav";

function Play({quest, booking, prompts, token, playerRole, activePromptId, setActivePromptId}) {
    const [sessionData, setSessionData] = useState(null);
    const [csrfToken, setCsrfToken] = useState(null);

    const [activeTab, setActiveTab] = useState("quest");

    const [answer, setAnswer] = useState("");
    const [wrongAnswer, setWrongAnswer] = useState(false);
    const [previousPrompt, setPreviousPrompt] = useState([]);

    const [newEvidence, setNewEvidence] = useState(false);
    const [newQuestion, setNewQuestion] = useState(false);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    function clean(str) {
        return str.replace(/\s+/g, ' ').replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").trim().toLowerCase();
    }

    // If there is no previous prompt, set the previous prompt to the current prompt. Mostly this is for quest start.
    // Previous prompt is only relvent to the maps tab.
    useEffect(() => {
        if(prompts && activePromptId && !previousPrompt){
            setPreviousPrompt(prompts.filter(p => p.id === activePromptId)[0]);
        }
    }, [activePromptId]);
    // ------------------------------------------------------

    // Post new evidence and questions as events on new prompt load
    useEffect(() => {
        if (sessionData && playerRole === "captain") {
            const currentPrompt = prompts.find(prompt => prompt.id === activePromptId);
            console.log(currentPrompt);

            if (Array.isArray(currentPrompt.end_game_questions) && currentPrompt.end_game_questions.length > 0) {
                currentPrompt.end_game_questions.forEach((question) => {
                    if (!sessionData.events.some(event => event.event_data?.id === question.id && event.event_type === "question found")) {
                        postQuestEvent({ event_type: "question found", event_data: question });
                        setNewQuestion(true);
                    }
                });
            }

            if (currentPrompt && Array.isArray(currentPrompt.evidence) && currentPrompt.evidence.length > 0) {
                for (let i = 0; i < currentPrompt.evidence.length; i++) {
                    if (!sessionData.events.some(event => event.event_data.id === currentPrompt.evidence[i].id)) {
                        postQuestEvent({event_type: "evidence found", event_data: currentPrompt.evidence[i]});
                        setNewEvidence(true);
                    }
                }
            }
        }
    }, [activePromptId]);
    // ------------------------------------------------------

    // Post Quest Event
    // example usage: 
    // postQuestEvent({event_type: "answer submitted", event_data: answer})
    // postQuestEvent({event_type: "new prompt", event_data: prompt})
    const postQuestEvent = async function(body) {
        try {
            const response = await fetch(`${API_BASE_URL}/events/${token}/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrfToken,
                },
                body: JSON.stringify(body)
            });
            const result = await response.json();
            // console.log(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    // ------------------------------------------------------

    const patchSessionData = async function(body) {
        try {
            const response = await fetch(`${API_BASE_URL}/quest-session/${token}/`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrfToken,
                },
                body: JSON.stringify(body)
            });
            const result = await response.json();
            // console.log(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleSubmitAnswer = async (prompt) => {
        postQuestEvent({event_type: "answer submitted", event_data: answer});
        let newPromptId
        for (let i = 0; i < prompt.next_prompt_ids.length; i++) {
            if (clean(prompts.filter(p => p.id === prompt.next_prompt_ids[i])[0].previous_prompt_answer) === clean(answer)) {
                newPromptId = prompts.filter(p => p.id === prompt.next_prompt_ids[i])[0].id
            }
        }
        if (newPromptId) {
            setPreviousPrompt(prompt);
            await patchSessionData({active_prompt: newPromptId});
            setActivePromptId(newPromptId);
            postQuestEvent({event_type: "new prompt reached", event_data: prompts.filter(p => p.id === newPromptId)[0]});
        }else {
            setWrongAnswer(true);
        }
    }
    useEffect(() => {
        setWrongAnswer(false);
    }, [answer]);

    // Polling based session parity - maybe switch to websockets at some point
    useEffect(() => {
        const updateSession = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/quest-session/${token}`);
                const result = await response.json();
                console.log(result['quest_session']);
                setActivePromptId(result['quest_session']['active_prompt']);
                setSessionData(result['quest_session']);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        updateSession();
        const interval = setInterval(updateSession, 4000);
        return () => clearInterval(interval);
    }, []);
    // ------------------------------------------------------


    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/get-csrf-token`);
                const result = await response.json();
                setCsrfToken(result['csrfToken']);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (!csrfToken) {
            fetchCsrfToken();
        }
    }, []);

    if (!sessionData) {
        return (
            <div className="QuestRoomContent">
                <h2>Loading...</h2>
            </div>
        );
    }
    if (!sessionData.quest_started) {
        return (
            <div className="QuestRoomContent">
                <WaitForCaptain role={playerRole} token={token} quest={quest} setSessionData={setSessionData} csrfToken={csrfToken}/>
            </div>
        )
    }

    const currentPrompt = prompts.filter(prompt => prompt.id === activePromptId)[0];

    const renderTab = () => {
        switch (activeTab) {
            case "evidence":
                return <EvidenceTab sessionData={sessionData} setNewEvidence={setNewEvidence}/>
            case "hints":
                return <HintsTab prompt={currentPrompt} playerRole={playerRole}/>
            case "history":
                    return <HistoryTab sessionData={sessionData}/>
            case "map":
                    return <MapTab quest={quest} prompt={currentPrompt} previousPrompt={previousPrompt}/>
            case "questions":
                    return <QuestionsTab sessionData={sessionData} setNewQuestion={setNewQuestion} />
            case "quest":
                return <QuestTab wrongAnswer={wrongAnswer} playerRole={playerRole} prompt={currentPrompt} setAnswer={setAnswer} answer={answer} handleSubmitAnswer={handleSubmitAnswer}/>
        }
    }


    return (
        <div style={{position: "relative"}} className="QuestRoomContent">
            <QuestTimer timeUp={sessionData.quest_deadline}/>
            {renderTab()}
            <FooterNav setActiveTab={setActiveTab} quest={quest} newEvidence={newEvidence} newQuestion={newQuestion} />
        </div>
    );
}

export default Play;