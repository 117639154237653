import React from "react";

function FooterNav({setActiveTab, quest, newEvidence, newQuestion}) {

    return (
        <div className="FooterNav">
            <div onClick={() => setActiveTab("quest")} className="FooterNavItem">Quest</div>
            {quest.category.title === "CityQuest" && <div onClick={() => setActiveTab("map")} className="FooterNavItem">Map</div>}
            <div onClick={() => setActiveTab("evidence")} className={newEvidence ? 'FooterNavItemNew' : 'FooterNavItem'}>Evidence</div>
            <div onClick={() => setActiveTab("hints")} className="FooterNavItem">Hints</div>
            <div onClick={() => setActiveTab("history")} className="FooterNavItem">History</div>
            <div onClick={() => setActiveTab("questions")} className={newQuestion ? 'FooterNavItemNew' : 'FooterNavItem'}>Questions</div>
        </div>
    );
}

export default FooterNav;