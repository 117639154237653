import React, { useState, useEffect } from "react";
import { SocialIcon } from 'react-social-icons'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL

const tripAdvisorIcon = `${MEDIA}/media/icons8-trip-advisor-48.png`

const trustpilotIcon = `${MEDIA}/media/icons8-trustpilot-48.png`


function CheckUsOut() {
    const [images, setImages] = useState([]);
    const fetchImages = async () => {
        const endpoint = `${API_BASE_URL}/check-us-out-images`;
        try {
            const response = await fetch(endpoint);
            const data = await response.json();
            // console.log("check-us-out-images", data)
            setImages(data);
        } catch (error) {
            // console.error("Error fetching images:", error);
        }
    }
    
    useEffect(() => {
        fetchImages();
    }, [])

    const showImages = () => {
        if (!images || images.length === 0) return null;
        const getRandomImages = (imageArray, num) => {
            const shuffled = imageArray.sort(() => 0.5 - Math.random());
            return shuffled.slice(0, num);
        }
        const randomImages = getRandomImages(images, 6);
        return randomImages.map((image) => {
            const image_url = `${MEDIA}${image.image}`
            return (
                <img src={image_url} alt={image.alt} key={image.id}/>
            )
        });
    }

    return (
        <div className="CheckUsOut">
            <div className="CheckUsOutLeft">
                <div className="CheckUsOutLeftGallery">
                    {showImages()}
                </div>
            </div>
            <div className="CheckUsOutRight">
                <h1>Check us out!</h1>
                <p>We post on a number of social media platforms as a way to let our users 
                    keep up with the great discounts and events we run!
                </p>
                <div className="CheckUsOutRightSocials">
                    <SocialIcon className="SocialIcons"  target="_blank" url="https://www.instagram.com/ardi.global/" />
                    <SocialIcon className="SocialIcons" target="_blank" url="https://www.facebook.com/ARDI.Global/" />
                    <SocialIcon className="SocialIcons"  target="_blank" url="https://www.linkedin.com/company/14790324" />
                    <a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g189852-d17344395-Reviews-ARDI-Stockholm.html">
                        <img src={tripAdvisorIcon} alt="Trip Advisor" />
                    </a>
                    <a target="_blank" href="https://se.trustpilot.com/review/ardi.one">
                        <img src={trustpilotIcon} alt="Trust Pilot" />
                    </a>
                    <SocialIcon className="SocialIcons"  target="_blank" network="google" url="https://g.page/ardi-one" />

                </div>
            </div>
        </div>
    );
}

export default CheckUsOut