import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { validateForm } from "../../tools";
import { format } from 'date-fns';
import { languageCodetoVerbose } from "../../tools";

function BookingStepTwo({formData, setFormData, setShowPaymentElement, showPaymentElement, setClientSecret, selectedQuest, csrfToken}) {

    const [missingFields, setMissingFields] = useState([]);
    const [errorMessages, setErrorMessages] = useState({});
    const [promoCodeResponse, setPromoCodeResponse] = useState('');
    const [showTOSError, setShowTOSError] = useState(false);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


    function nextStep() {
        const requiredFields = ["firstName", "lastName", "email", "phone", "tos"];
        const missing = [];
        setShowPaymentElement(false);
        setShowTOSError(false);
        const validationErrors = validateForm({'email': formData.email, 'phone': formData.phone});
        requiredFields.forEach((field) => {
          if (!formData[field]) {
            missing.push(field);
          }
        });
        if (missing.length > 0) {
          setMissingFields(missing);
          if (!formData.tos) {
            setShowTOSError(true);
          }
          return;
        }
        if (!validationErrors.email && !validationErrors.phone) {
          setShowPaymentElement(true);
          setErrorMessages(validationErrors);
        } else {
          setErrorMessages(validationErrors);
        }
        setMissingFields([]);
      }

      const checkMissingField = (field) => {
        return missingFields.includes(field) ? "BookingStepMissingField" : "";
      }

      function CheckPromoCode() {
        fetch(`${API_BASE_URL}/promo-code/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken
          },
          body: JSON.stringify(formData),
        }).then(response => response.json())
        .then(data => {
          // console.log(data)
          // console.log(formData)
          setPromoCodeResponse(data['message'])
          const newPrice = data['new_price'];
          if (data['valid']) {
            fetch(`${API_BASE_URL}/create-payment-intent/`, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  "X-CSRFToken": csrfToken
              },
              body: JSON.stringify({
                  amount: newPrice,
                  currency: selectedQuest.price_per_unit.currency,
                  formData: formData
              }),
          }).then(async (result) => {
              const { clientSecret, id } = await result.json();
              setClientSecret(clientSecret);
              setFormData({ ...formData, stripePaymentId: id, priceWithPromo: newPrice });
          });
  
          }
        })
      }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
        setShowPaymentElement(false);
      };

      function generateTeams(numPeople, maxTeamSize) {
        const numTeams = Math.ceil(numPeople / maxTeamSize);
        const teams = Array(numTeams).fill(0).map(() => []);
        let people = Array.from({ length: numPeople }, (_, i) => `Person ${i + 1}`);
        let teamIndex = 0;
    
        while (people.length) {
            if (teams[teamIndex].length < maxTeamSize) {
                teams[teamIndex].push(people.shift());
            }
            teamIndex = (teamIndex + 1) % numTeams;
        }
        const teamSizeFrequency = {};
        teams.forEach((team) => {
            const size = team.length;
            if (teamSizeFrequency[size]) {
                teamSizeFrequency[size]++;
            } else {
                teamSizeFrequency[size] = 1;
            }
        });
        let teamStructure = `${numPeople} players in ${numTeams} ${numTeams > 1 ? 'teams' : 'team'}<br/>`;
        if (numTeams > 1) { 
          for (const [size, count] of Object.entries(teamSizeFrequency)) {
            teamStructure += `${count} team${count > 1 ? 's' : ''} of ${size} players<br/>`;
          }
        }
        return teamStructure.trim();
    }

    return(
        <div className="BookingStep">


            <div className="BookingStepTwoInfoRow">
              <div className="BookingStepTwoInfoColumn">
                <h3>Quest Type:<br/>{selectedQuest.category.title}</h3>
                <h3>Quest Language:<br/>{languageCodetoVerbose(selectedQuest.language)}</h3>
              </div>

              <div className="BookingStepTwoInfoColumn">
                <h3>Quest:<br/>{selectedQuest.title}</h3>
                <h3>Time:<br/>{format(new Date(formData.date), 'hh:mm a')}, {format(new Date(formData.date), 'dd MMM yyyy')}</h3>
              </div>
            </div>
            <div className="BookingStepTwoInfoRow">
              <div className="BookingStepTwoInfoColumn">
                <h3>Team structure:</h3>
                <h3 dangerouslySetInnerHTML={{ __html: generateTeams(formData.players, selectedQuest.max_players_per_team) }}></h3>
              </div>
            </div>


            <div className="BookingStepRow">
                <div className="BookingStepColumn">
                    <label>First Name:</label>
                    <input value={formData.firstName} onChange={handleChange} name="firstName" className={`BookingStepSelect ${checkMissingField('firstName')}`}/>
                </div>
                <div className="BookingStepColumn">
                    <label>Last Name:</label>
                    <input value={formData.lastName} onChange={handleChange} name="lastName" className={`BookingStepSelect ${checkMissingField('lastName')}`}/>
                </div>
            </div>
            <div className="BookingStepRow">
                <div className="BookingStepColumn">
                    <label>Email:</label>
                    <input value={formData.email} onChange={handleChange} name="email" className={`BookingStepSelect ${checkMissingField('email')}`}/>
                </div>
                <div className="BookingStepColumn">
                    <label>Phone Number:</label>
                    <input value={formData.phone} onChange={handleChange} name="phone" className={`BookingStepSelect ${checkMissingField('phone')}`}/>
                </div>
            </div>
            <div className="BookingStepRow">
              <div className="BookingStepError"> 
                {errorMessages.email ?
                  errorMessages.email
                : null}
              </div>
              <div className="BookingStepError"> 
                {errorMessages.phone ?
                  errorMessages.phone
                : null}
              </div>
            </div>
            <div className="BookingStepRow">
                <div className="BookingStepColumn">
                    <label>Promo Code:</label>
                    <input onChange={handleChange} name="promo" value={formData.promo} className="BookingStepInput"/>
                </div>
                <div style={{alignItems: "flex-start"}} className="BookingStepColumn">
                    <label>&nbsp;</label>
                    <button onClick={() => CheckPromoCode()} className="BookingStepPromoButton">Activate</button>
                </div>
            </div>
            <h4>{promoCodeResponse}</h4>
            <div className="BookingStepRow">
              <div style={{textAlign: "left"}} className="BookingStepError"> {showTOSError && !formData.tos ? "Please agree to our Terms of Service" : null} </div>
            </div>

            <div className="BookingStepRow">
              <div className="BookingStepTOSInputContainer">
                      <button name="tos" value={formData.tos} onClick={() => setFormData({ ...formData, tos: !formData.tos })}>{formData.tos ? "X" : ""}</button>
                      <label>By checking this box, you agree to our <a href="/terms" target="_blank">Terms of Service</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</label>
              </div>
                <div className="BookingStepMailingListInputContainer">
                    <button onClick={() => setFormData({ ...formData, mailingList: !formData.mailingList })}>{formData.mailingList ? "X" : ""}</button>
                    <label>Uncheck to not recieve information,<br/> discounts and updates via email.</label> 
                </div>
            </div>

            <div className="BookingStepRow">
              <div className="BookingStepColumn">

              <label>How did you hear about us?</label>
              <select label="HowDidYouHearAboutUs" onChange={(e) => setFormData({ ...formData, howDidYouHearAboutUs: e.target.value })} name="HowDidYouHearAboutUs" className="BookingStepSelect">
                <option value="">Select</option>
                <option value="FaceBook">FaceBook</option>
                <option value="Instagram">Instagram</option>
                <option value="Google">Google</option>
                <option value="Youtube">Youtube</option>
                <option value="TripAdvisor">TripAdvisor</option>
                <option value="Newsletter">Newsletter</option>
                <option value="Word of Mouth">Word of Mouth</option>
                <option value="Other">Other</option>
              </select>
              </div>
            </div>

            <div className="BookingStepRow">
                <label style={{textAlign: "right"}}>Price: ${formData.promo ? formData.priceWithPromo : formData.price}</label>
            </div>
            {showPaymentElement ? null :
            <div style={{justifyContent: "center"}} className="BookingStepRow">
                <button onClick={() => nextStep()} className="BookingStepButton">Next &gt;</button>
            </div>
            }
        </div>
    ) 
}

export default BookingStepTwo