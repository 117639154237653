import React from "react";

function TrustedBy() {

    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL

    const imageLink = `${MEDIA}/media/trusted-by.png`

    return (
        <div className="TrustedBy">
            <h3>Trusted by many happy customers, including</h3>
            <img src={imageLink} alt="Trusted By" />
        </div>
    );
}

export default TrustedBy