import React from "react";

function HistoryTab({sessionData}) {

    const renderHistory = () => {
        if (sessionData?.events) {
            return sessionData.events.map((event, index) => {
                if (event.event_type === "answer submitted") {
                    return (
                        <div className="HistoryTabEvent" key={index}>
                            <h4>Answer Submitted -&gt; "{event.event_data}"</h4>
                        </div>
                    );
                } else if (event.event_type === "new prompt reached") {
                    return (
                        <div className="HistoryTabEvent" key={index}>
                            <h4>New Stage Reached -&gt; {event.event_data.previous_prompt_answer}</h4>
                            <p dangerouslySetInnerHTML={{ __html: event.event_data.text_rendered }}/>
                        </div>
                    );
                } else {
                    return null;
                }
            });
        }
        return null;
    };
    

    return (
        <div className="HistoryTab">
            <h1>Quest History</h1>
            <div className="HistoryTabEventContainer">
                {renderHistory()}
            </div>
        </div>
    );
}

export default HistoryTab;