import React, { useState, useEffect } from "react";
import CategoryCard from "./CategoryCard";
// import {testCategory} from "../../Test-Data/categories";

function WhatKindOfQuester() {
    const [categories, setCategories] = useState([]);
    const [selectedTab, setSelectedTab] = useState("None");

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const categoriesRes = await fetch(`${API_BASE_URL}/categories`);
                if (!categoriesRes.ok) {
                    throw new Error(`HTTP error! Status: ${categoriesRes.status}`);
                }
                const categoriesData = await categoriesRes.json();
                setCategories(categoriesData);
            } catch (err) {
                console.error("Fetch error:", err);
            }
        }
        fetchCategories();
    }, [])

    const sortOrders = {
        "Adventure": [0, 3, 1],
        "Home": [1, 4, 5],
        "Escape": [2, 0, 5],
        "None": [0, 1, 2, 3, 4, 5]
    }

    const handleTabClick = (e) => {
        if (selectedTab === e.target.id) {
            setSelectedTab("None");
        }
        else {
            setSelectedTab(e.target.id);
        }
    }

    const showCategories = (tab) => {
        if (tab === "None") {
            return categories.map((category, index) => {
                return (
                    <CategoryCard key={index} category={category} />
                )
            })
        } else {
            return categories.map((category, index) => {
                if (sortOrders[tab].includes(index)) {
                    return (
                        <CategoryCard key={index} category={category} />
                    )
                } else {
                    return null;
                }
            })
        }
    }

    // const getTabClass = (tab) => {
    //     return selectedTab === tab ? "WhatKindOfQuesterSortTabsSelected" : "";
    // };

    return (
        <div className="WhatKindOfQuesterContainer">
            <div className="WhatKindOfQuester" id="WhatKindOfQuester">
                <h1 style={{color: '#F6511D'}}>What Kind of</h1>
                <h1>Quester Are You?</h1>
            </div>

            {/* <div className="WhatKindOfQuesterSortTabs">
                <button id="Adventure" onClick={handleTabClick}>
                    I Want To Go On An Adventure!
                </button>
                <button id="Home" onClick={handleTabClick}>
                    Solving Scary Mysteries! <br/>
                    From The Safety Of My Couch
                </button>
                <button id="Escape" onClick={handleTabClick}>
                    I Want To Share My Awesome Escape-Room<br/>Skills With All My Colleagues
                </button>
            </div> */}

            <div className="CategoryCardContainer">
                {showCategories(selectedTab)}
            </div>
        </div>
    );
}
export default WhatKindOfQuester