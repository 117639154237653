import React from "react";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

function QuestTab({prompt, setAnswer, handleSubmitAnswer, answer, playerRole, wrongAnswer}) {

    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

    // console.log(prompt);

    return (
    <div className="QuestTab">
        {playerRole === "captain" && 
            <div className="QuestTabAnswer">
                <input className={wrongAnswer ? "QuestTabInputWrongAnswer" : ""} type="text" value={answer} onChange={(e) => setAnswer(e.target.value)}/>
                <button onClick={() => handleSubmitAnswer(prompt)}>Submit</button>
            </div>
        }
        <p>{prompt.question}</p>
        <h1 style={{margin: "0px"}} className="QuestTabTitle">{prompt.previous_prompt_answer}</h1>
        <p className="QuestTabText" dangerouslySetInnerHTML={{ __html: prompt.text_rendered }}/>
        {prompt.image && <img className="QuestTabImage" src={`${MEDIA}${prompt.image}`} alt="Place Holder"/>}

        <AudioPlayer
        src={`${MEDIA}${prompt.audio}`}
        // onPlay={(e) => console.log("onPlay")}
        autoPlay={false}
        layout="horizontal"
        className="QuestTabAudioPlayer"
        />
    </div>
    );
}

export default QuestTab;