import React, { useState, useEffect } from "react";

function SelectRole({playerName, setPlayerName, token, setPlayerRole, setPlayerInTeam, csrfToken}) {
    const [message, setMessage] = useState("");

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    
    const createNewPlayer = (role) => {
        console.log(token, playerName, role);
        fetch(`${API_BASE_URL}/create-player/${token}/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
            body: JSON.stringify({
                name: playerName,
                role: role,
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            if (data['player_created']) {
                setPlayerInTeam(true)
                setPlayerRole(data['player_created']['role']);
            } else if (data['error']) {
                console.log(data['error']);
                setMessage(data['error']);
            }
        })
        .catch(err => {
            console.log(err);
        });
    };

    return (
        <div className="QuestRoomContent Centered">
            <h3>Your player name is:</h3>
            <h1 style={{marginTop: "0"}}>{playerName}</h1>
            <h3>Make a note of it.<br/>You will need it to rejoin the quest if you lose your connection.</h3>
            <br/>
            <p>Your team will need a captain.</p>
            <p>The captain is reponsible for submitting the decisions your team will make thoughout the quest.</p>
            <p>Speak to your team and select a captain, then use the buttons below to join the quest.</p>
            <br/>
            <div>
                <button onClick={() => createNewPlayer("captain")} className="JoinButtons">Join As Captain</button>
                <button onClick={() => createNewPlayer("quester")} className="JoinButtons">Join As Quester</button>
            </div>
            <h3 style={{color: "orange", marginTop: "0"}}>{message}</h3>
        </div>
    )
}

export default SelectRole;