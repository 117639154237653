import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";


function WhatIsThisCategory({category, quests}) {
    const [currentQuestIndex, setCurrentQuestIndex] = useState(0);

    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL
    const imageLink = `${MEDIA}${category.card_image}`

    const navigate = useNavigate();

    const buttonCSS = {
        backgroundColor: category.primary_color,
        color: "#FFF",
        border: "none",
    }
    const buttonHoverCSS = {
        backgroundColor: "#FFF",
        color: category.primary_color,
        border: `1px solid ${category.secondary_color}`,
    }


    useEffect(() => {
        if (quests.length > 0) {
            const interval = setInterval(() => {
                if (currentQuestIndex < quests.length - 1) {
                    setCurrentQuestIndex(currentQuestIndex + 1);
                } else {
                    setCurrentQuestIndex(0);
                }
            }, 6000);
            return () => clearInterval(interval);
        }
    }, [quests, currentQuestIndex]);

    const renderFadingImages = useCallback(() => {
        if (quests.length > 0) {
            return quests.map((quest, index) => (
                    <div
                        className={`WhatIsThisCategoryLeftImage ${currentQuestIndex === index ? 'Show' : ''}`}
                        style={{ backgroundImage: `url(${MEDIA}${quest.card_image})`, cursor: "pointer" }}
                        key={quest.id}
                        onClick={() => navigate(`/quests/info/${quest.id}`)}
                    />
            ));
        }
    }, [quests, currentQuestIndex, MEDIA]);

    return (
        <div className="WhatIsThisCategory">

            <div className="WhatIsThisCategoryLeft">
                <div style={{backgroundColor: category.primary_color}} className="WhatIsThisCategoryLeftColorBlock"></div>
                {renderFadingImages()}
            </div>

            <div className="WhatIsThisCategoryRight">
                <h1>What is <br/>{category.title}?</h1>
                <div style={{width: "100%"}}>
                    <hr style={{borderColor: category.primary_color}}/>
                </div>
                <p dangerouslySetInnerHTML={{ __html: category.long_description_rendered }}/>
                <div style={{width: "100%"}}>
                    <a href={`/faq/${category.title}`}>
                    <button style={buttonCSS} onMouseOver={(e) => {
                        Object.assign(e.target.style, buttonHoverCSS);
                    }} onMouseOut={(e) => {
                        Object.assign(e.target.style, buttonCSS);
                    }}>
                        FAQ
                    </button>
                </a>
                </div>
            </div>
        </div>
    );
}

export default WhatIsThisCategory