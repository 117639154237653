import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { languageCodetoVerbose } from "../../tools";

function BookingStepOne({setStep, formData, setFormData, categories, quests, setSelectedQuest, selectedQuest}) {

    const [categoryQuests, setCategoryQuests] = useState([]);
    const [missingFields, setMissingFields] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);

    function nextStep() {
        const requiredFields = ["category", "language", "quest", "players", "date"];
        const missing = [];
        requiredFields.forEach((field) => {
          if (!formData[field]) {
            missing.push(field);
          }
        });
        if (missing.length > 0) {
          setMissingFields(missing);
          return;
        }
        setMissingFields([]);
        setStep(2);
      }
      const checkMissingField = (field) => {
        return missingFields.includes(field) ? "BookingStepMissingField" : "";
      }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    useEffect(() => {
        if (!formData.category && categories.length > 0 && !selectedQuest) {
        setFormData({ ...formData, category: categories[0].id.toString() });
    } else if (!formData.category && categories.length > 0 && selectedQuest) {
        if (selectedQuest.category){
            setFormData({ ...formData, category: selectedQuest.category.id.toString() });
        } else if (categories.length > 0) {
            setFormData({ ...formData, category: categories[0].id.toString() });
        }
    }
    }, [categories, formData, setFormData]);

    useEffect(() => {
        const filteredQuests = quests.filter(quest => quest.category.id.toString() === formData.category);

        setCategoryQuests(filteredQuests);

        if (filteredQuests.length > 0 && formData.quest === "") {
            setFormData({
                ...formData,
                quest: filteredQuests[0].id.toString(),
                language: filteredQuests[0].language
            });
        }
    }, [formData.category, quests]);

    useEffect(() => {
        if (formData.quest && quests.length > 0) {
            const findQuest = quests.find(quest => quest.id.toString() === formData.quest);
            if (findQuest) {
                setSelectedQuest(findQuest);
            }
        }
    }, [formData.quest, quests, setSelectedQuest]);

    useEffect(() => {
        if (formData.players && selectedQuest.price_per_unit) {
            setFormData({
                ...formData,
                price: Number(selectedQuest.price_per_unit.amount) * Number(formData.players)
            })
        }
    }, [formData.players, selectedQuest]);
    
    const showQuests = () => {
        return categoryQuests.map((quest) => {
        return (
        <option key={`q-${quest.id}`} value={quest.id}>
            {quest.title}
        </option>
        );
    });
    };
    const showCategories = () => {
        return categories.map((category) => {
            return (
                <option key={`c-${category.id}`} value={category.id}>{category.title}</option>
            )
        })
    }
    const showLanguages = () => {
        if(formData.quest){
            const quest = categoryQuests.find(quest => quest.id.toString() === formData.quest);
            if (quest) {
                return(
                    <option key={`${quest.id}-${quest.language}`} value={quest.language}>{languageCodetoVerbose(quest.language)}</option>
                )
            }
        } else {
            return (
                <option>Select a Quest</option>
            )
        }
    }

    return(
        <div className="BookingStep">
            <div className="BookingStepRow">
                <div className="BookingStepColumn">
                    <label>Category:</label>
                    <select value={formData.category} onChange={handleChange} name="category" className={`BookingStepSelect ${checkMissingField('category')}`}>
                        {showCategories()}
                    </select>
                </div>
                <div className="BookingStepColumn">
                    <label>Preferred Language:</label>
                    <select value={formData.language} onChange={handleChange} name="language" className={`BookingStepSelect ${checkMissingField('language')}`}>
                        {showLanguages()}
                    </select>
                </div>
            </div>
            <div className="BookingStepRow">
                <div className="BookingStepColumn">
                    <label>Quest:</label>
                    <select value={formData.quest} onChange={handleChange} name="quest" className={`BookingStepSelect ${checkMissingField('quest')}`}>
                        {showQuests()}
                    </select>
                </div>
            </div>
            <div className="BookingStepRow">
                <div style={{width: "60%", alignItems: "flex-start"}} className="BookingStepColumn">
                    <label>How many players?</label>
                    <input 
                        style={{width: "40%"}} 
                        onChange={handleChange} 
                        name="players" 
                        value={formData.players} 
                        className={`BookingStepInput ${checkMissingField('players')}`} 
                        type="number" 
                        min={selectedQuest.min_players_per_team ? selectedQuest.min_players_per_team : 1} max="1000"/>
                </div>
                <div className="BookingStepColumn">
                    <p>
                        Note: The optimal number of players per team is {selectedQuest.min_players_per_team} - {selectedQuest.max_players_per_team}. <br/>
                        If there are more than {selectedQuest.max_players_per_team} players, the system will automatically divide
                        you into teams with a maximum of {selectedQuest.max_players_per_team} players each.
                    </p>
                </div>
            </div>
            <div className="BookingStepRow">
                <div className="BookingStepColumn">
                    <label>Choose your date and time:</label>
                    <div className={`BookingDatePickerContainer ${checkMissingField('date')}`}>
                        <DatePicker 
                            className="BookingDatePicker" 
                            wrapperClassName="BookingDatePicker" 
                            selected={formData.date} 
                            onChange={date => setFormData({ ...formData, date })}
                            showTimeSelect
                            timeFormat="h:mm aa"
                            timeIntervals={15}
                            dateFormat="h:mm aa - MMMM d, yyyy"
                            placeholderText="Select a date and time"
                            />
                    </div>
                </div>
                <div className="BookingStepColumn">
                </div>
            </div>
            <div className="BookingStepRow">
                <label style={{textAlign: "right"}}>Price: ${formData.price}</label>
            </div>
            <div style={{justifyContent: "center"}} className="BookingStepRow">
                <button onClick={nextStep} className="BookingStepButton">Next &gt;</button>
            </div>
        </div>
    ) 
}

export default BookingStepOne