import React from "react";

function HintsTab({prompt, playerRole}) {
    return (
        <div className="HintsTab">
            <h2>Feeling stuck?</h2>
            {playerRole === "captain" ? <button>Request a Hint</button> : <p>Your captain can request a hint be made visible here.</p>}
            <p>Note: Using hints will effect your team's final score.</p>
        </div>
    );
}

export default HintsTab;