import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';

// Components ----------------------------------------------------------------
import Layout from './Components/Layout';
import Home from './Components/Home';
import NotFound from './Components/NotFound';
// import Games from './Components/Games';
import News from './Components/News';
import NewsItem from './Components/NewsItem';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Terms from './Components/Terms';
import Creators from './Components/Creators';
import CategoryOverview from './Components/CategoryOverview';
import QuestInfo from './Components/QuestInfo';
import FAQ from './Components/FAQ';
import Booking from './Components/Booking/Booking';
import QuestClient from './Components/QuestClient/QuestClient';
// ---------------------------------------------------------------------------
function App() {

    // useEffect(() => {
    //   const loadCSS = async () => {
    //     if (window.innerWidth < 768) {
    //       const mobileStyles = await import('./AppMobile.css');
    //     } else {
    //       const desktopStyles = await import('./App.css');
    //     }
    //   };
    //   loadCSS();
    // }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout> <Home /> </Layout>} />
        <Route path="/quests/:type" element={<Layout> <CategoryOverview /> </Layout>} />
        <Route path="/quests/info/:id" element={<Layout> <QuestInfo  /> </Layout>} />
        <Route path="/faq/:categoryName" element={<Layout> <FAQ /> </Layout>} />
        <Route path="/faq" element={<Layout> <FAQ /> </Layout>} />
        <Route path="/news" element={<Layout> <News /> </Layout>} />
        <Route path="/news-item/:id" element={<Layout> <NewsItem /> </Layout>} />
        <Route path="/privacy-policy" element={<Layout> <PrivacyPolicy /> </Layout>} />
        <Route path="/terms" element={<Layout> <Terms /> </Layout>} />
        <Route path="/creators" element={<Layout> <Creators /> </Layout>} />
        <Route path="/booking" element={<Layout> <Booking /> </Layout>} />
        <Route path="/booking/:id" element={<Layout> <Booking /> </Layout>} />
        <Route path="/play" element={ <QuestClient /> } />
        <Route path="/play/:joinToken" element={ <QuestClient /> } />
        <Route path="*" element={<Layout> <NotFound /> </Layout>} />
      </Routes>
    </div>
  );
}

export default App;