import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

function EvidenceTab({sessionData, setNewEvidence}) {

    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

    setNewEvidence(false);

    const renderEvidence = () => {
        if (sessionData?.events) {
            const evidenceItems = sessionData.events.filter(event => event.event_type === "evidence found");

            if (evidenceItems.length === 0) {
                return <p>Nothing to see here yet, keep investigating.</p>;
            }

            return evidenceItems.map((event) => (
                <div className="EvidenceTabItem" key={event.id}>
                    <br/>
                    <h3>{event.event_data.title}</h3>
                    {event.event_data.image && <img src={MEDIA + event.event_data.image} alt={event.event_data.title}/>}
                    {event.event_data.audio && 
                        <AudioPlayer 
                            src={MEDIA + event.event_data.audio} 
                            showSkipControls={true} 
                            showJumpControls={false} 
                            onPlay={() => {}} 
                            onPause={() => {}}
                            className="EvidenceTabAudioPlayer"
                        />}
                    <p dangerouslySetInnerHTML={{ __html: event.event_data.text_rendered }}/>
                </div>
            ));
        }
        return null;
    };

    return (
        <div className="EvidenceTab">
            <h1>Evidence</h1>
            <div className="EvidenceTabContent">
                {renderEvidence()}
            </div>
        </div>
    );
}

export default EvidenceTab;