import React, { useEffect, useState } from "react";

function QuestTimer({ timeUp }) {
    const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        const targetTime = new Date(timeUp).getTime();
        const intervalId = setInterval(() => {
            const now = Date.now();
            const remainingTime = targetTime - now;

            const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
            
            // add hours to minutes if not diplaying hours
            let count = hours
            while (count > 0) {
                minutes += 60
                count -= 1
            }
            // -------------------------------------------------

            if (remainingTime >= 0) {
                setTimeLeft({ hours, minutes, seconds });
            } else {
                clearInterval(intervalId);
                setTimeLeft(null);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeUp]);

    if (timeLeft === null) {
        return (
        <div className="QuestTimerContainer">
            <div className="QuestTimer">
                <h3>Time's up!</h3>
            </div>
        </div>
        );
    }

    return (
        <div className="QuestTimerContainer">
            <h3>Time Remaining:</h3>
            <div className="QuestTimer">
                {/* <p>{timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}</p>
                <h2>:</h2> */}
                <p>{timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}</p>
                <h2>:</h2>
                <p>{timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}</p>
            </div>
        </div>
    );
}

export default QuestTimer;
