import React, { useState, useEffect } from "react";

import { ardiIcon } from "../../tools";

import FullTeamOrRejoin from "./FullTeamOrRejoin";
import JoinQuest from "./JoinQuest";
import SelectRole from "./SelectRole";
import Play from "./Play";

function QuestRoom({quest, booking, prompts, token}) {
    const [activePromptId, setActivePromptId] = useState(null);
    const [playerInTeam, setPlayerInTeam] = useState(false);
    
    const [playerName, setPlayerName] = useState("");
    const [playerRole, setPlayerRole] = useState(null);

    const [joinAsNewPlayer, setJoinAsNewPlayer] = useState(false);

    const [error, setError] = useState(null);

    const [csrfToken, setCsrfToken] = useState(null);
    

    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const QuestRoomBackgroudCss = {
        backgroundImage: `url(${MEDIA}/media/patterns/medium-low-opacity.svg)`,

        backgroundRepeat: "no-repeat, repeat",
        backgroundSize: "90vw, 80vh",
        backgroundPosition: "center",
    }

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/get-csrf-token`);
                const result = await response.json();
                setCsrfToken(result['csrfToken']);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (!csrfToken) {
            fetchCsrfToken();
        }
    }, []);

    useEffect(() => {
        if (!playerName) {
            // Also checks if Team is full
            const getPlayerName = async () => {
                try {
                    const response = await fetch(`${API_BASE_URL}/get-player-name/${token}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });
                    const data = await response.json();
                    console.log(data);
                    if (data['name']){
                        setPlayerName(data['name'])
                        setPlayerInTeam(true)
                    }else if (data['error']) {
                        setError(data['error']);
                    }
                } catch (err) {
                    setError(err);
                }
            }
            getPlayerName();
        }
    }, [playerName]);

    if (error && error === "Team is full" && !playerInTeam) {
        return (
            <div style={QuestRoomBackgroudCss} className="QuestRoom">
                    <FullTeamOrRejoin 
                        setPlayerRole={setPlayerRole}
                        token={token}
                        setPlayerInTeam={setPlayerInTeam}
                        setPlayerName={setPlayerName} 
                    />
            </div>
        )
    }

    if (!joinAsNewPlayer && !playerRole) {
        return (
            <div style={QuestRoomBackgroudCss} className="QuestRoom">
                    <JoinQuest
                        setPlayerRole={setPlayerRole}
                        token={token}
                        setPlayerInTeam={setPlayerInTeam}
                        playerName={playerName}
                        setPlayerName={setPlayerName}
                        joinAsNewPlayer={joinAsNewPlayer}
                        setJoinAsNewPlayer={setJoinAsNewPlayer}
                    />
            </div>
        );
    }

    if (!playerRole) {
        return(
            <div style={QuestRoomBackgroudCss} className="QuestRoom">
                <SelectRole 
                    playerName={playerName} 
                    setPlayerName={setPlayerName} 
                    token={token}
                    playerRole={playerRole}
                    setPlayerRole={setPlayerRole}
                    csrfToken={csrfToken}
                    setPlayerInTeam={setPlayerInTeam}
                />
            </div>
        );
    }

    return (
        <div style={QuestRoomBackgroudCss} className="QuestRoom">
            <Play 
                quest={quest}
                booking={booking}
                prompts={prompts}
                token={token}
                playerRole={playerRole}
                activePromptId={activePromptId}
                setActivePromptId={setActivePromptId}
            />
        </div>
    );
}

export default QuestRoom