import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FAQItemDisplay from "./Sub-Components/FAQItemDisplay";

function FAQ() {

    const { categoryName } = useParams();
    const [faqs, setFaqs] = useState([]);
    const [category, setCategory] = useState({});

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const res = await fetch(`${API_BASE_URL}/faq/`);
                if (!res.ok) {
                    throw new Error(`Failed to fetch: ${res.statusText}`);
                }
                const data = await res.json();
                console.log(data);
                setFaqs(data);
            } catch (error) {
                // console.error("Error fetching FAQs:", error);
            }
        };
        fetchFaqs();
    }, []);

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const res = await fetch(`${API_BASE_URL}/categories/${categoryName}`);
                if (!res.ok) {
                    throw new Error(`Failed to fetch: ${res.statusText}`);
                }
                const data = await res.json();
                // console.log(data);
                setCategory(data);
            } catch (error) {
                // console.error("Error fetching category:", error);
            }
        };
        if (categoryName){
            fetchCategory();
        }
    }, [categoryName]);

    return (
        <div style={{backgroundColor: categoryName ? category.primary_color : "#0077FF"}} className="FAQ LimitWidthParent">
            <hr/>
            <div className="FAQContainer LimitWidthChild">
                <h2>{categoryName}</h2>
                <h1>Frequently Asked Questions</h1>
                <FAQItemDisplay faqs={faqs} category={category} categoryName={categoryName} />
            </div>
        </div>
    );
}

export default FAQ