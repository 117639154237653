import React, { useState } from "react";
// import "./FAQItem.css"; // Import the CSS for styling

function FAQItem({ faq }) {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <div onClick={toggleExpanded} className="FAQItem">
            <h3>{faq.question}</h3>
            {/* <div className="FAQQuestion" dangerouslySetInnerHTML={{ __html: faq.question_rendered}}/> */}
            <hr className={expanded ? "FAQAnswerShowHR" : ""} />
            <div className={`FAQAnswerHide ${expanded ? "FAQAnswerShow" : ""}`}>
                <div className="FAQAnswer" dangerouslySetInnerHTML={{ __html: faq.answer_rendered }} />
            </div>
        </div>
    );
}

export default FAQItem;