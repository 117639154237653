import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";


function NewsItem() {
    const [news, setNews] = useState(null);
    const [error, setError] = useState(null);
    
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL

    const newsItemid = useParams().id;

    const navigate = useNavigate();

    const backgroundCss = {
        backgroundImage: `url(${MEDIA}/media/patterns/large-low-opacity.svg)`,
        backgroundRepeat: "repeat, repeat",
        backgroundPosition: "cover",
    };

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/news/${newsItemid}`);
                const data = await response.json();
                setNews(data);
            } catch (err) {
                setError(err.message);
            }
        };
        fetchNews();
    }, [newsItemid]);

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!news) {
        return <p>Loading...</p>;
    }

    return (
        <div style={backgroundCss} className="NewsItem">
            <button className="NewsItemBackButton" onClick={() => navigate(`/news`)}>&#8592; Back</button>
            <div className="NewsItemContent">
                <h1>{news.title}</h1>
                <h4 dangerouslySetInnerHTML={{ __html: news.subtitle_rendered }} />
                <img src={`${MEDIA}/${news.image}`} alt="news image" />
                <p dangerouslySetInnerHTML={{ __html: news.content_rendered }} />
            </div>
        </div>
    );
}

export default NewsItem;
