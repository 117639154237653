import React, { useEffect } from "react";

import { ardiIconAnimated } from "../../tools";

import CountDownClock from "./CountDownClock";

function CountDown({booking, quest, bookingDateTime, onComplete}) {

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date();
            const timeLeft = bookingDateTime - now;

            if (timeLeft <= 0) {
                clearInterval(intervalId);
                onComplete();
            }
        }, 500);

        return () => clearInterval(intervalId);
    }, [bookingDateTime, onComplete]);


    return(
        <div className="CountDown">
            {ardiIconAnimated(quest.category.primary_color)}
            <div>
                <h1 className="CountDownText">{quest.category.title}</h1>
                <h2 className="CountDownText">{quest.title}</h2>
                <br/>
                {/* <h4>{booking.num_players} players</h4> */}
                {/* <h4>
                    {bookingDateTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} &nbsp;-&nbsp;
                    {bookingDateTime.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                </h4> */}
            </div>
            <div>
                <h4 className="CountDownText">Your Quest Begins In:</h4>
                <CountDownClock targetDate={bookingDateTime} />
            </div>
        </div>
    )
}

export default CountDown