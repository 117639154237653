import React from "react";

function Footer() {

    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL

    const callIcon = `${MEDIA}/media/CallIcon.png`

    const mailIcon = `${MEDIA}/media/MailIcon.png`

    const locationIcon = `${MEDIA}/media/Location-Icon.png`

    return (
        <div className="Footer">
            <h1>Developed by © ARDI GLOBAL LLC</h1>
            <hr/>
            <div className="FooterLower">
                <div>
                    <h4>Reach Us</h4>
                    <div className="ReachUsIconContainer">
                        <img src={callIcon} alt="Call Icon"/>
                        +1 309 249 5773
                    </div>
                    <a href="mailto:hello@ardi.one">
                        <div className="ReachUsIconContainer">
                            <img src={mailIcon} alt="Email Icon"/>
                            hello@ardi.one
                        </div>
                    </a>
                    <div className="ReachUsIconContainer">
                        <img src={locationIcon} alt="Email Icon"/>
                        <div>
                            ______________________<br/>
                        Peoria, Illinois 61604 United States
                        </div>
                    </div>
                </div>
                <div>
                    <h4>Company</h4>
                    {/* About
                    <br/>
                    <br/> */}
                    <a href="/news">News</a>
                </div>
                <div>
                    <h4>Legal</h4>
                    <a href="/privacy-policy">Privacy Policy</a>
                    <br/>
                    <br/>
                    <a href="/terms">Terms of Service</a>
                    {/* <br/>
                    <br/>
                    Refund Policy */}
                </div>
                {/* <div>
                    <h4>Quick Links</h4>
                    Socials
                    <br/>
                    <br/>
                    Downloads
                    <br/>
                    <br/>
                    Forums
                </div> */}
                <div className="FooterNewsletterSignup">
                    <h4>Join Our Newsletter</h4>
                    <div>
                        <input type="text" placeholder="Your email address"/>
                        <button>Subscribe</button>
                    </div>
                    <p>* We will send you quarterly updates and never spam with other marketing or advertisements. </p>
                </div>
            </div>
        </div>
    );
}

export default Footer