import React, { useCallback, useRef, useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, DirectionsRenderer } from '@react-google-maps/api';

const Map = ({ origin, destination, quest }) => {
  const [center, setCenter] = useState(null);
  const [originObj, setOriginObj] = useState(null);
  const [destinationObj, setDestinationObj] = useState(null);

  const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      libraries: ['places']
  });
  
  useEffect(() => {
    if (originObj) {
        setOriginObj({ lat: origin[0], lng: origin[1] });
    }
    if (destinationObj) {
        setDestinationObj({ lat: destination[0], lng: destination[1] });
    }
  }, [originObj, destinationObj]);

  useEffect(() => {
    if (quest) {
        setCenter({ lat: quest.meeting_point_lat, lng: quest.meeting_point_long });
    } else {
        setCenter({ lat: 59.3293, lng: 18.0686 });
    }
  }, [quest]);
  
  const directionsService = useRef(null);
  const [directions, setDirections] = React.useState(null);

  const calculateRoute = useCallback(() => {
      if (!originObj || !destinationObj) return;

      if (!directionsService.current) {
          directionsService.current = new window.google.maps.DirectionsService();
      }

      directionsService.current.route(
          {
              origin: originObj,
              destination: destinationObj,
              travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
              if (status === 'OK' && result) {
                  setDirections(result);
              } else {
                  console.error('Error fetching directions:', status);
              }
          }
      );
  }, [originObj, destinationObj]);

  const mapCenter = originObj || center;

  const mapStyles = [
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
      }
  ];

    const mapOptions = {
      zoomControl: false, // Disable zoom control
      scrollwheel: false, // Disable scroll zoom
      draggable: false, // Disable dragging
      disableDefaultUI: true, // Disable all UI controls (like zoom, pan)
      gestureHandling: 'none', // Disable all map gestures like pan, pinch zoom
      styles: mapStyles
    };

    useEffect(() => {
        if (isLoaded) calculateRoute();
    }, [isLoaded, calculateRoute]);

    return isLoaded ? (
      <div className='MapContainer'>
        <GoogleMap
            zoom={12}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={mapCenter}
            options={mapOptions}
        >
            {directions && <DirectionsRenderer directions={directions} />}
        </GoogleMap>
      </div>
    ) : (
        <div>Loading...</div>
    );
};

export default Map;
